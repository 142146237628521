<template>
  <div class="columns login_grid_height m-0">
    <div class="column is-6 left-section">
      <div class="welcome-carousel-container">
        <Carousel />
      </div>
    </div>
    <div class="column is-6 overlay-bg auth-form-container">
      <Loading :isLoading="isLoadingLogin" :canCancel="false" />
      <div class="column is-6 login-form">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <p class="main-text is-size-2">Log In into your account</p>
        <p class="child-text mb-4">
          Log In to access dashboard and see team activity
        </p>
        <!-- <div class="buttons btn-container">
            <button class="btn click" @click.prevent="authProvider('google')">
              <img src="../../../assets/images/google-logo.svg" alt="" />
              <p>Log in With Google</p>
            </button>
            <button @click="msLogin" class="btn click">
              <img src="../../../assets/images/microsoft-logo.svg" alt="" />
              <p>Log in With Microsoft</p>
            </button>
          </div>
          <div class="line-break"><span>or</span></div> -->

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(doLogin)">
            <EmailInput
              :placeholder="'e.g: admin@roketin.com'"
              @email="emitEmail"
              input-id="form_login_email"
            ></EmailInput>

            <validation-provider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                label="Password"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                class="input-field"
              >
                <b-input
                  id="form_login_password"
                  class="password-eye"
                  type="password"
                  placeholder="password"
                  password-reveal
                  v-model="password"
                ></b-input>
              </b-field>
            </validation-provider>
            <b-field class="is-flex is-justify-content-flex-start remember">
              <!-- <b-checkbox class="has-text-weight-normal">
                Keep me signed in
              </b-checkbox> -->
              <router-link
                to="password-reset"
                class="is-link has-text-weight-semibold"
                id="btn_forgotpassword"
              >
                Forgot Password
              </router-link>
            </b-field>
            <b-field class="is-flex is-justify-content-center login-cta mb-1">
              <b-button
                id="form_login_submit"
                native-type="submit"
                class="is-fullwidth"
                @click="doLogin()"
              >
                Login
              </b-button>
            </b-field>
          </form>
        </validation-observer>

        <div class="register-cta is-flex">
          <router-link to="registration" id="btn_register" class="is-link">
            Don’t have account?
            <span class="has-text-weight-semibold">Register Now</span>
          </router-link>
        </div>

        <div class="text-footers">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}. RISE
            {{ appVersion }}
          </p>
        </div>
      </div>
    </div>

    <!-- Modal Verification -->
    <Modal
      :show="isModalOpen"
      :hide="closeModalRequest"
      :isEdit="isEditing"
      :class="'modal-allowed-capacity'"
    >
      <template #form>
        <div class="is-flex is-justify-content-center">
          <img src="../../../assets/images/email-sender.svg" alt="" />
        </div>
        <div class="has-text-centered mt-5">
          <h5>Please Verify Account</h5>
        </div>
        <div class="mx-6">
          <p class="modal-otp-text mx-6">
            Enter the link digit code we sent to your email address to verify
            your Rise account
          </p>
        </div>
        <div class="is-flex is-justify-content-center mt-5">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            v-model="code"
            @on-complete="handleOnComplete"
          />
        </div>
        <div class="buttons m-5">
          <b-button
            :loading="isLoading"
            @click="verifyOtp()"
            type="is-primary"
            expanded
          >
            Verify & Proceed
          </b-button>
        </div>
        <div class="has-text-centered mb-6">
          <a @click="resendCode()" class="resend-code">Resend Code</a>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { appVersion } from '@/version'
import Carousel from '../../../components/Carousel.vue'
import EmailInput from '../../../components/EmailInput.vue'
import Loading from '../../../components/Loading.vue'
import Modal from '../../../components/Modals/Modal.vue'
import { AuthMixin } from '../mixins/AuthMixin'

// import { mapActions } from 'vuex'

export default {
  components: { Carousel, Modal, EmailInput, Loading },
  mixins: [AuthMixin],
  data() {
    return {
      code: '',
      email: '',
      password: '',
      message: '',
      isLoading: false,
      isLoadingLogin: false,
      isCardModalActive: false,
      isModalOpen: false,
      datas: null,
      isEditing: false,
    }
  },

  computed: {
    appVersion: () => appVersion,
  },
  methods: {
    /**
     * Emit email value from child component
     * @param {String} val - email input value
     */
    emitEmail(val) {
      this.email = val
    },
  },
}
</script>
